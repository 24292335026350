<template>
  <div class="trip-background">
    <!-- <template>
      <div id="photos-container">
        <div id="photo-cover" @click="photosClick">
          <div id="photo-item"></div>
          <div id="photo-item-label">Photos</div>
        </div>
      </div>
    </template> -->
    <template v-if="!trip.displayName">
      <div class="main-container space-around">
        <div class="info">
          <div v-if="!trip" class="title">Trip ID not Valid</div>
          <div v-else class="title">Loading ...</div>
          <div class="subtitle">———</div>
          <div class="subtitle">{{ $route.params.id }}</div>
          <div class="description"></div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="top-container space-around">
        <div class="main-container space-around">
          <div class="info">
            <div class="title" v-text="trip.displayName"></div>
            <div
              class="subtitle"
              v-text="trip.displayName2 + ' | ' + trip.date"
            ></div>
            <div class="description" v-text="trip.description"></div>
          </div>

          <div class="download-buttons">
            <div class="download-title secondary-title">
              <span>Trip Documents</span>
              <!-- <span
                :href="`/documents/{doc.file}`"
                :onclick="
                  `return confirm('Are you sure you want to download: {doc.name}?')`
                "
                download
                class="download-button"
              >
                ALL
                <v-icon right dark>
                  mdi-download
                </v-icon>
              </span> -->
            </div>
            <div
              v-if="
                !this.trip.documents ||
                  Object.keys(this.trip.documents).length <= 0
              "
              class="subtitle"
            >
              No Documents Available
            </div>
            <template v-for="doc in this.trip.documents">
              <a
                :key="doc.file"
                :id="doc.file"
                :href="`/documents/${doc.file}`"
                :onclick="
                  `return confirm('Are you sure you want to download: ${doc.name}?')`
                "
                download
                class="download-button"
              >
                {{ doc.name }}
                <v-icon right dark>
                  mdi-download
                </v-icon>
              </a>
            </template>
          </div>
        </div>

        <div class="main-container space-around">
          <div class="contacts">
            <div class="secondary-title">Contacts</div>
            <template v-for="(contact, idx) in trip.contacts">
              <div :key="idx" class="contact">
                <div class="contact-row">
                  <div class="name" v-text="contact.name"></div>
                  <a
                    class="email link"
                    :title="contact.email"
                    :href="'mailto:' + contact.email"
                    target="_blank"
                    v-text="contact.email"
                  ></a>
                </div>
                <div class="contact-row">
                  <div class="role" v-text="contact.role"></div>
                  <a
                    class="phone link"
                    :title="contact.phone"
                    :href="'tel:' + contact.phone"
                    target="_blank"
                    v-text="contact.phone"
                  ></a>
                </div>
              </div>
            </template>
          </div>

          <div class="venmo">
            <div class="secondary-title">Venmo</div>
            <venmo
              v-if="trip.donate && trip.donate.venmo_tag"
              class="venmo-section"
              :trip="trip"
            />
            <div v-else class="subtitle">No Venmo Available</div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { firebase } from "../plugins";
import Venmo from "../components/Venmo";

export default {
  name: "Trip",
  components: { Venmo },
  data: () => ({
    trip: { contacts: {} },
    headers: [
      { text: "Name", value: "name" },
      { text: "Contact", value: "contact" }
    ]
  }),
  watch: {
    "$route.params.id": {
      immediate: true,
      handler() {
        firebase.tripData(
          this.$route.params.id,
          doc => (this.trip = doc.data())
        );
      }
    }
  },
  methods: {
    photosClick() {
      console.log("photosClick")
    }
  }
};
</script>

<style scoped>
.top-container {
  display: flex;
  flex-direction: row;
  column-gap: 48px;
  padding: 48px;
}

@media (max-width: 800px) {
  .trip-background::before {
    /* background-color: transparent; */
    width: calc(100vw - 20px);
  }

  .top-container {
    padding: 24px 24px;
  }
}

.main-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.space-between {
  justify-content: space-between;
}
.space-around {
  justify-content: space-around;
}
.space-center {
  justify-content: center;
}

#photos-container {
  position: fixed;
  bottom: 76px;
  right: 20px;
  height: 120px;
  width: 120px;
  background: var(--bwb-dark-hover);
  border-radius: 9999px;
  display: flex;
  padding: 8px;
}

#photo-cover {
  height: 100%;
  width: 100%;
  position: relative;

}

#photo-cover:hover > #photo-item-label {
  display: flex;
}

#photo-item-label {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  
  height: 100%;
  width: 100%;

  display: none;
  align-items: center;
  justify-content: center;

  border-radius: 9999px;
  background-color: rgba(0, 0, 0, 0.75);
  text-decoration: underline;
  cursor: pointer;
}

#photo-item {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;

  height: 100%;
  width: 100%;

  border-radius: 9999px;
  background-image: url('https://bwbutah.org/img/IMG_2002.d749931e.jpg');
  background-position: center center;
  background-size: 300% 200%;
}


.title {
  text-align: center;
  font-size: 40px;
  font-weight: bold;
}

.secondary-title {
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 15px;
}

.info > .subtitle {
  font-size: 24px;
}

.subtitle {
  text-align: center;
  /* color: rgb(210, 210, 210); */
}
.description {
  max-width: 800px;
  margin: 30px auto;
}

.contacts {
  margin: auto;
  max-width: 300px;
  min-width: 300px;
}
.contact {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 20px;
}
.contact-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 5px;
}
.link {
  color: rgb(20, 20, 20);
}
.link:hover {
  color: rgba(20, 20, 20, 0.5);
}

.name {
  font-size: 16px;
}
.role {
  font-size: 14px;
}

.email {
  text-align: right;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 190px;
  white-space: nowrap;
}

.venmo {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--footer-color);
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 10px;
}
.venmo > .secondary-title, .venmo > .subtitle {
  color: white !important;
}
.venmo-section {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.download-buttons {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: auto;
  row-gap: 5px;
}

.download-title {
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  justify-content: center;
}

.download-button {
  border-radius: 10px;
}
.download-button:hover {
  background-color: var(--bwb-dark-hover);
}
a.download-button {
  background-color: var(--footer-color);
  color: white;
  
  padding: 10px 20px;
  max-width: 500px;
  
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
span.download-button {
  color: var(--footer-color);
  padding: 2px 20px 6px 20px;
  margin-top: auto;
  margin-bottom: auto;
  font-size: 20px;
  border: 2px solid var(--footer-color);
}

.flex-item {
  flex: 20%;
}

@media (max-width: 800px) {
  .top-container {
    flex-direction: column;
    row-gap: 60px;
  }

  .info {
    flex: 100%;
  }

  .contacts {
    flex: 100%;
  }

  .venmo {
    flex: 100%;
    margin-top: 20px;
  }

  .download-buttons {
    flex: 100%;
    margin-top: 20px;
  }
}
</style>
